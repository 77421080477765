<template>
  <section>
    <v-row>
      <v-col cols="12">
        <v-breadcrumbs large :items="breadcrumbsItems"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ml-5">
        <p class="text-h6 secondary--text mb-0">
          Unidad de Retención de Talento Humano
        </p>
        <p class="text-h6 secondary--text mb-0">
          14111500 Papel de imprenta y papel de escribir
        </p>
        <p class="text-subtitle-1 secondary--text mb-0">
          Clase: 14111500 Papel de imprenta y de escribir
        </p>
        <p class="text-subtitle-1 secondary--text mb-0">
          Mercancía: 14111511 Papel de escritura
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ml-5">
        <p>Cotizaciones</p>
        <v-btn color="secondary" class="bgWhite--text">Agregar</v-btn>
      </v-col>
    </v-row>
    <v-row class="ml-2">
      <v-col cols="12">
        <DataTable :headers="headers" :items="items" :show_actions="false">
        </DataTable>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-center mt-5">
        <p class="text-h6 secondary--text mb-0">Precio promedio de mercado</p>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <p class="text-h6 secondary--text mb-0 mt-0">$ 2.45</p>
      </v-col>
      <v-col cols="12" class="d-flex justify-center mb-0">
        <v-chip outlined> $ 2.30 </v-chip>
        <hr class="custom-hr" />
        <v-chip outlined> $ 2.50 </v-chip>
      </v-col>
    </v-row>
    <v-row class="ml-2">
      <v-col cols="12">
        <p class="text-6 secondary--text">Detalle del insumo</p>
      </v-col>
      <v-col cols="12">
        <DataTable :headers="headers2" :items="items2">
          <template v-slot:[`body.append`]>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <span> Total:</span> <span class="text-h5 ml-5">$12.50</span>
              </td>
            </tr>
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <v-row class="mt-10 ml-2">
      <v-col cols="12">
        <v-textarea outlined label="Especificación"></v-textarea>
      </v-col>
    </v-row>
    <v-row class="ml-2">
      <v-col cols="12">
        <v-btn color="secondary" outlined>Volver</v-btn>
        <chat></chat>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";
import chat from "@/components/ChatComponent.vue";

export default {
  name: "detalleInsumoPaac",
  components: {
    DataTable,
    chat,
  },
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Proceso",
        disable: true,
        href: "#",
      },
      {
        text: "0200-2022-P1232",
        disable: true,
        href: "#",
      },
      {
        text: "14111500 papel bond - Resma",
        disable: true,
        href: "#",
      },
      {
        text: "Unidad de Retencion de Talento Humano",
        disable: true,
        href: "#",
      },
    ],
    headers: [
      {
        text: "Documento",
        align: "start",
        width: "70%",
        sortable: true,
        value: "documento",
      },
      {
        text: "Precio unitario ($)",
        align: "start",
        sortable: true,
        value: "precioUnitario",
      },
      {
        text: "Fecha carga",
        align: "start",
        sortable: true,
        value: "fechaCarga",
      },
    ],
    headers2: [
      {
        text: "Correlativo",
        align: "start",
        sortable: true,
        value: "correlativo",
      },
      {
        text: "Mes",
        align: "start",
        sortable: true,
        value: "mes",
      },
      {
        text: "Cantidad",
        align: "start",
        sortable: true,
        value: "cantidad",
      },
      {
        text: "Precio unitario ($)",
        align: "start",
        sortable: true,
        value: "precioUnitario",
      },
      {
        text: "Total ($)",
        align: "start",
        sortable: true,
        value: "total",
      },
    ],
    items: [
      {
        documento: "Cotizacion 1",
        precioUnitario: "100",
        fechaCarga: "2021-05-01",
      },
      {
        documento: "Cotizacion 2",
        precioUnitario: "200",
        fechaCarga: "2021-05-01",
      },
      {
        documento: "Cotizacion 3",
        precioUnitario: "300",
        fechaCarga: "2021-05-01",
      },
    ],
    items2: [
      {
        correlativo: "1",
        mes: "Enero",
        cantidad: "100",
        precioUnitario: "100",
        total: "10000",
      },
      {
        correlativo: "2",
        mes: "Febrero",
        cantidad: "200",
        precioUnitario: "200",
        total: "40000",
      },
      {
        correlativo: "3",
        mes: "Marzo",
        cantidad: "300",
        precioUnitario: "300",
        total: "90000",
      },
    ],
  }),
};
</script>

<style scoped>
.custom-hr {
  width: 100px;
  margin-top: 15px;
  color: "secondary";
}
</style>
